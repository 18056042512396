// read text 
function TextAdjuster (props) {
    let {text, adjustmentQueries} = props;
    const charCount = text.length || 0;

    //sorting the query length so that we take the first valid one
    adjustmentQueries = adjustmentQueries.sort((x)=>x.minLength);
    const query = adjustmentQueries.find((x)=>charCount > x.minLength);
    let adjustmentClass = '';
    if(query)
        adjustmentClass = query.adjustmentClass;

    return <span className={`text text--${adjustmentClass}`}>{text}</span>

}

export default TextAdjuster;