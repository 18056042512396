import '../styles/components/_ProgressBar.scss';

function ProgressBar ({progress}) {
    return (
        <div className='progress-bar'>
            <div className='progress-bar__progress' style={{width:`${progress * 100}%`}}></div>
        </div>
    )
}

export default ProgressBar;