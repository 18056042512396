import '../styles/components/_MenuBarButton.scss'
// define a button that is bar of a menu bar that allow to toggle state
function MenuBarButton (props) {

    const {label,children,onClick,pressed} = props;

    return (
            <button className={`${props.className} menu-bar-button`} type='button' aria-label={label} onClick={(onClick)} aria-pressed = {pressed}>
                {children}
            </button>  
    )

}

export default MenuBarButton;