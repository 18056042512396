import '../styles/components/_MediaPlayerButton.scss';
import ScreenReaderText from'./ScreenReaderText'

function MediaPlayerButton (props){
    const {icon, iconAlt, label, readLabel, selected, onClick ,flex=1} = props;
    const componentClassName = 'media-player-btn'
    return (
        <button style={{flex}} src={icon} className={`${componentClassName} ${selected ? `${componentClassName}--selected`:''}`} onClick={onClick}>
            <img className='media-player-btn__icon' src={icon} alt={iconAlt} />
            <span className='media-player-btn__label'><ScreenReaderText visibleText={label} screenReaderText={readLabel}/></span>
        </button>
    );
}

export default MediaPlayerButton;