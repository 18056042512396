const MediaDescriptions =  [
    {
        id:0,
        chapterId:1,
        //title: 'Evolv hands-off security scan',
        title:'Welcome',
        mediaLink:'ViewBoston_Chapter01_AD.mp3'
    },
    {
        id:1,
        chapterId:2,
        title: 'Security Queue Lobby',
        mediaLink:'ViewBoston_Chapter02_AD.mp3'
    },
    {
        id:2,
        chapterId:3,
        title: 'Security Scan',
        mediaLink:'ViewBoston_Chapter03_AD.mp3'
    },
    {
        id:3,
        chapterId:4,
        title: 'Elevator Queue',
        mediaLink:'ViewBoston_Chapter04_AD.mp3'
    },
    {
        id:4,
        chapterId:5,
        title: 'Elevator Up',
        mediaLink:'ViewBoston_Chapter05_AD.mp3'
    },
    {
        id:5,
        chapterId:6,
        title: 'Floor 52, The View',
        mediaLink:'ViewBoston_Chapter06_AD.mp3'
    },
    {
        id:6,
        chapterId:7,
        title: 'Tactile Model Southeast',
        mediaLink:'ViewBoston_Chapter07_AD.mp3'
    },
    {
        id:7,
        chapterId:8,
        title: 'South Lookout',
        mediaLink:'ViewBoston_Chapter08_AD.mp3'
    },
    {
        id:8,
        chapterId:9,
        title: 'Tactile Model Southwest',
        mediaLink:'ViewBoston_Chapter09_AD.mp3'
    },
    {
        id:9,
        chapterId:10,
        title: 'Virtual Viewers and West View',
        mediaLink:'ViewBoston_Chapter10_AD.mp3'
    },
    {
        id:10,
        chapterId:11,
        title: 'Tactile Model Northwest',
        mediaLink:'ViewBoston_Chapter11_AD.mp3'
    },
    {
        id:11,
        chapterId:12,
        title: 'North Lookout',
        mediaLink:'ViewBoston_Chapter12_AD.mp3'
    },
    {
        id:12,
        chapterId:13,
        title: 'Tactile Model Northeast',
        mediaLink:'ViewBoston_Chapter13_AD.mp3'
    },
    {
        id:13,
        chapterId:14,
        title: 'Floor 51, The Cloud Terrace',
        mediaLink:'ViewBoston_Chapter14_AD.mp3'
    },
    {
        id:14,
        chapterId:15,
        title: 'Welcome to Floor 50: The City; Boston 365',
        mediaLink:'ViewBoston_Chapter15_AD.mp3'
    },
    {
        id:15,
        chapterId:16,
        title: 'Explore Boston',
        mediaLink:'ViewBoston_Chapter16_AD.mp3'
    },
    {
        id:16,
        chapterId:17,
        title: 'Open Doors Immersive Theater',
        mediaLink:'ViewBoston_Chapter17_AD.mp3'
    },
    {
        id:17,
        chapterId:18,
        title: 'Gift Shop and Studio 50',
        mediaLink:'ViewBoston_Chapter18_AD.mp3'
    },
    {
        id:18,
        chapterId:19,
        title: 'The Finale and the Beacon',
        mediaLink:'ViewBoston_Chapter19_AD.mp3'
    },
    {
        id:19,
        chapterId:20,
        title: 'Elevator Down',
        mediaLink:'ViewBoston_Chapter20_AD.mp3'
    },
    {
        id:20,
        chapterId:21,
        title: 'Exit',
        mediaLink:'ViewBoston_Chapter21_AD.mp3'
    },

]


export default MediaDescriptions;