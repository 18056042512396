import '../styles/containers/_MediaList.scss'
import { createRef, useEffect, useState } from 'react'

function MediaList(props) {
  const {
    activeMediaId,
    playlistActions,
    playState,
    medias,
    mediaHistory,
  } = props
  const [itemsRefs, setItemRefs] = useState([])

  useEffect(() => {
    const refs =  medias.reduce((acc, media) => {
        acc[media.id] = createRef()
        return acc
      }, [])

    setItemRefs(
        refs
    )
    // eslint-disable-next-line 
  }, [])

  useEffect(() => {
    if (!itemsRefs[activeMediaId]) return

    itemsRefs[activeMediaId].current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
// eslint-disable-next-line 
  },[activeMediaId])

  
  const renderMedias = () => {
    let itemComponentClassName = 'media-list__item'

    return medias.map((i) => {

      // setting the status class as upcomming by default
      let statusClass = `${itemComponentClassName}--upcomming`
      // if we already played it we set it's class to passed
      statusClass =  mediaHistory.find(j=>i.id === j) !== undefined ? `${itemComponentClassName}--passed` : statusClass
      // if it is currently playing we apply the current class
      statusClass = i.id === activeMediaId ? `${itemComponentClassName}--current` : statusClass


      let playStateClass = playState &&  i.id === activeMediaId   ? `${itemComponentClassName}--paused` : '';
      let ariaActionLabel = 'play'
      if(i.id === activeMediaId){
        if(playState)
          ariaActionLabel = 'pause'
      }else if(mediaHistory.find(j=>i.id === j) !== undefined ){
        ariaActionLabel = 'replay'
      }
 

      return (
        <li
          className={`${itemComponentClassName} ${statusClass} ${playStateClass}`}
          aria-label='test'
          key={i.id}
          ref={itemsRefs[i.id]}
        >
          <button
            aria-label={`${ariaActionLabel} Chapter ${i.chapterId} ${i.title} item ${i.chapterId} of ${medias.length} of the chapter list`}
            className="media-list__btn"
            type="button"
            onClick={()=> {
              if(i.id === activeMediaId)
                playlistActions.setPlayState(!playState)
              else
              playlistActions.goTo(i.id)

            }}
          >
            <div className="media-list__item-description-wrapper">
              <h1 className="media-list__item-chapter">{`Chapter ${i.chapterId}`}</h1>
              <h2 className="media-list__item-title">{i.title}</h2>
            </div>
          </button>
        </li>
      )
    })
  }

  return <ol className="media-list">{renderMedias()}</ol>
}

export default MediaList
