import { useEffect, useState } from 'react';
import './styles/_app.scss';
import Themes from './constants/themes';
import PageHeader from './containers/PageHeader';
import MediaList from './containers/MediaList';
import MediasDescriptions from './assets/data/mediaDescriptions';
import TurnDeviceIcon from './assets/images/svg/turn_device_icon.svg';

function App() {
  const [theme, setTheme] = useState(Themes.Default);
  const [mediaState,setMediaState] = useState({ id:0,playState:false })
  let [aboutSectionShownState, setAboutSectionShownState] = useState(false)
  const [mediaHistory, setMediaHistory] = useState([]);
  const mediaCount = MediasDescriptions.length;

  const playMedia = id => {
    setMediaHistory([...new Set([...mediaHistory, mediaState.id])])
    setMediaState({id,playState:true })
  };

  const setPlayState =  (playState) =>{
    setMediaState({...mediaState,playState})
  }

  const goToNextMedia = ()=>{
    setMediaState(mediaState => ({...mediaState, id: (mediaState.id + 1) % mediaCount, playState:false}))
  }

  const goToPrevMedia = ()=>{
    setMediaState(mediaState => ({...mediaState, id: (mediaState.id - 1) > 0 ? mediaState.id - 1 : mediaCount - 1, playState:false}))
  }

  // delete the experience state and pause the video
  const reset = () => {
    setMediaState({id:0, playState: false});
    setMediaHistory([])
  };

  // looking if a video as been triggered to play to add it to the media history
  useEffect(
    () => {
      if (mediaState.playState)
        setMediaHistory([...new Set([...mediaHistory, mediaState.id])]);
    },
    // eslint-disable-next-line 
    [mediaState.playState]
  );

  const playlistActions = {
    next:goToNextMedia,
    previous: goToPrevMedia,
    goTo: id => playMedia(id),
    setPlayState: setPlayState,
    reset
  };

  const props = {
    theme,
    mediaState,
    setTheme,
    playlistActions,
    activeMediaId: mediaState.id,
    medias: MediasDescriptions,
    playState:mediaState.playState,
    mediaHistory,
    aboutSectionShownState,
    setAboutSectionShownState
  };
  return (
    <div className={`app theme-${theme}`}>
      <div className='app__wrapper'>
        <PageHeader {...props} />
        <div className='app__content-push'></div>
        <div aria-hidden={aboutSectionShownState}><MediaList {...props} /></div>
      </div>
      <div className='app__orientation-cta'>
        <p className='app__orientation-cta-prompt'>
          rotate device back to portrait mode to use audio player
        </p>
        <img
          className='app__orientation-cta-icon'
          src={TurnDeviceIcon}
          alt='icon prompting the user to turn their device to portrait orientation'
        />
      </div>
    </div>
  );
}

export default App;
