import '../styles/containers/_MediaPlayer.scss'
import { useState, useEffect, useRef, useCallback } from 'react'
import TextAdjuster from '../components/TextAdjuster'
import ProgressBar from '../components/ProgressBar'
import MediaPlayerButton from '../components/MediaPlayerButton'
import ScreenReaderText from '../components/ScreenReaderText'

import { addSeconds, format } from 'date-fns'

import JumpPreviousIcon from '../assets/images/svg/jump_previous.svg'
import PlayIcon from '../assets/images/svg/play.svg'
import PauseIcon from '../assets/images/svg/pause.svg'
import JumpAheadIcon from '../assets/images/svg/jump_ahead.svg'
import SkipPreviousIcon from '../assets/images/svg/skip_previous.svg'
import SkipNextIcon from '../assets/images/svg/skip_next.svg'

function MediaPlayer(props) {
  const { activeMediaId, medias, playlistActions,playState } = props
  const [mediaProgress, setMediaProgress] = useState(0)
  const [mediaDuration, setMediaDuration] = useState(0)
  const [readbleMediaDuration, setReadbleMediaDuration] = useState('')
  const [audioFile, setAudioFile] = useState()
  const player = useRef()

  const currentMedia = medias.find((x) => x.id === activeMediaId)
  const { title, chapterId } = currentMedia

  const onMediaLoaded =useCallback( () => {
    if(playState)
      player.current.play();
       // eslint-disable-next-line
  },[]);


  const onMediaEnded = useCallback(()=>{
    playlistActions.next()
     // eslint-disable-next-line
  },[])

  const onMediaTimeUpdate = () => {
    // not updating time if provided timestamp are not number
    if (
      Number.isNaN(player.current.currentTime) ||
      Number.isNaN(player.current.duration)
    )
      return

    // updating video progress
    let progress = player.current.currentTime / player.current.duration
    setMediaProgress(progress)

    // updating time remaning
    let date = addSeconds(
      new Date(0),
      player.current.duration - player.current.currentTime,
    )
    setMediaDuration(format(date, 'mm:ss'))
    setReadbleMediaDuration(`${format(date, 'm')} minutes ${format(date, 's')} seconds`)
  }

  const jumpInMedia = (delta) => {
    // not updating time if provided timestamp are not number
    if (
      Number.isNaN(player.current.currentTime) ||
      Number.isNaN(player.current.duration)
    )
      return
    let newTime = Math.max(0, Math.min(player.current.duration, player.current.currentTime + delta));
    player.current.currentTime = newTime;
  }

  // toggleing play state of the media player
  // we use the player to change the state
  const togglePlay = ()=>{
    playlistActions.setPlayState(!playState)
  }



  useEffect(() => {
    console.log("media loaded")
    let audioPlayer = player.current;
    audioPlayer.addEventListener('loadedmetadata', onMediaLoaded)
    audioPlayer.addEventListener('timeupdate', onMediaTimeUpdate)
    audioPlayer.addEventListener('canplay', onMediaTimeUpdate)
    audioPlayer.addEventListener('ended', onMediaEnded)
    audioPlayer.load();

    return () => {
      audioPlayer.removeEventListener('loadedmetadata', onMediaLoaded)
      audioPlayer.removeEventListener('timeupdate', onMediaTimeUpdate)
      audioPlayer.removeEventListener('canplay', onMediaTimeUpdate)
      audioPlayer.removeEventListener('ended', onMediaEnded)
    }
  }, [onMediaLoaded, onMediaEnded])

  useEffect(() => {
    ;(async () => {
      let file = (await import('../assets/audio/' + currentMedia.mediaLink))
        .default

      setAudioFile(file)
    })()

    setMediaProgress(0)
    player.current.load();
    return () => {}
  }, [activeMediaId, currentMedia.mediaLink])



  useEffect(() => {
    if(playState){
      player.current.play();
    }else{
      player.current.pause();
    }
    return () => {}
  }, [playState])

  return (
    <div className="media-player">
      <audio
        className="media-player__audio-player"
        src={audioFile}
        ref={player}
        preload='metadata'
        autoPlay={playState}
      />
      <div className="media-player__infos-group">
        <p className="media-player__chapter"><ScreenReaderText screenReaderText={`Currently playing Chapter ${chapterId} ${title}`} visibleText={`Chapter ${chapterId}`}/></p>
        <p aria-hidden className="media-player__title">
          <TextAdjuster
            text={title}
            adjustmentQueries={[{ minLength: 8, adjustmentClass: 'small' }]}
          />
        </p>
        <div className="media-player__progress-group">
          <div className="media-player__progress-bar">
            <ProgressBar progress={mediaProgress} />
          </div>
          <time className="media-player__timer" role="timer">
          <ScreenReaderText screenReaderText={`${readbleMediaDuration} remaining to the chapter`} visibleText={mediaDuration}/>
          </time>
        </div>
      </div>
      <div className="media-player__controls" role="menu">
        <div className="media-player__control-row" role="row">
          <MediaPlayerButton
            flex={1.55}
            icon={JumpPreviousIcon}
            label="Backward 10s"
            readLabel='Jump backward of ten seconds in the currently playing chapter'
            onClick={() => jumpInMedia(-10)}
          />
          <MediaPlayerButton
            flex={1}
            icon={playState ? PauseIcon :PlayIcon}
            selected = {playState}
            label={playState ? "Pause" : 'Play'}
            readLabel={`${playState ? "Pause" : 'Play'} currently ${playState ? "playing" : 'paused'} media`}
            onClick={togglePlay}
          />
          <MediaPlayerButton
            flex={1.55}
            icon={JumpAheadIcon}
            label="Forward 10s"
            readLabel='Jump forward of ten seconds in the currently playing chapter'
            onClick={() => jumpInMedia(10)}
          />
        </div>
        <div className="media-player__control-row" role="row">
          <MediaPlayerButton
            flex={1}
            icon={SkipPreviousIcon}
            label="Previous Chapter"
            readLabel="Move to the previous Chapter"
            onClick={playlistActions.previous}
          />
          <MediaPlayerButton
            flex={1}
            icon={SkipNextIcon}
            label="Next Chapter"
            readLabel="Move to the next Chapter"
            onClick={playlistActions.next}
          />
        </div>
      </div>
    </div>
  )
}

export default MediaPlayer
