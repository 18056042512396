import '../styles/containers/_PageHeader.scss'
import MenuBarButton from '../components/MenuBarButton'
import MediaPlayer from './MediaPlayer';
import ViewBostonLogo from '../assets/images/svg/view_boston_logo.svg'
import { About } from '../assets/texts'
import { ReactComponent as ContrastIcon } from '../assets/images/svg/theme.svg'
import { ReactComponent as InfoIcon } from '../assets/images/svg/info.svg'
import Themes from '../constants/themes'
import ScreenReaderText from '../components/ScreenReaderText';

function PageHeader(props) {
  const { playlistActions, theme, setTheme,aboutSectionShownState, setAboutSectionShownState } = props


  return (
    <header className={`page-header ${aboutSectionShownState ? 'page-header--aboutShown' : ''}`} >
      <div className="page-header__top-bar">
        <MenuBarButton onClick={playlistActions.reset} className={'page-header__logo'} label={'reset experience and clear listening history'}>
          <img src={ViewBostonLogo} alt='View Boston experience logo' className='page-header__logo'/>
        </MenuBarButton>

        <ul
          className="page-header__menu"
          role="menubar"
          aria-label="accessibility and help menu"
        >
          <li className="page-header__menu-btn page-header__theme-btn">
            <MenuBarButton
              label={'activate high contrast theme'}
              onClick={() =>
                setTheme(
                  theme === Themes.Default ? Themes.Contrast : Themes.Default,
                )
              }
              pressed={`${theme === Themes.Contrast}`}
            >
              <ContrastIcon />
            </MenuBarButton>
          </li>
          <li className={`page-header__menu-btn  page-header__about-btn ${aboutSectionShownState ? 'btn--active' : ''}`}>
            <MenuBarButton
              label={'show about section'}
              onClick={() => setAboutSectionShownState(!aboutSectionShownState)}
              pressed={`${aboutSectionShownState}`}
            >
                <InfoIcon/>
            </MenuBarButton>
          </li>
        </ul>
      </div>
      <MediaPlayer {...props} />
      <section className="page-header__about" aria-hidden={!aboutSectionShownState} aria-label="about section">
        <div className='page-header__about-content-wrapper'>
          <h1 className="page-header__about-title">{About.title}</h1>
          <p className="page-header__about-description">{About.description}</p>
          <a className="page-header__link" href="https://viewprint.viewboston.com/en/privacy-policy">Read our privacy policy</a>
        </div>
        <button onClick={() => setAboutSectionShownState(false)} className='page-header__about-close-btn' aria><ScreenReaderText visibleText={'Close'} screenReaderText={'Close the about section'}/></button>
      </section>
    </header>
  )
}

export default PageHeader
